import { compose, withHandlers, withState, withPropsOnChange } from 'recompose';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import FontExtension from './components/FontExtension';
import { handlePastedText } from './utils';
import { editorToolbarConfig } from './editorToolbarCfg';
import { TextEditorContainer, TextEditorErrorMessage, TextEditorWrapper } from './styled';
import { useEffect, useRef } from 'react';

const TextEditor = ({
  input,
  meta: { touched, error },
  editorState,
  onEditorStateChange,
  htmlContent,
  ...props
}) => {
  const editorRef = useRef(null);

  useEffect(() => {
    if (props.isEdit && editorRef.current) {
      editorRef.current.focusEditor();
    }
  }, [props.isEdit]);

  return (
    <TextEditorContainer>
      <TextEditorWrapper isEdit={props.isEdit}>
        {editorState && (
          <Editor
            ref={editorRef}
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            toolbarHidden={!props.isEdit}
            readOnly={!props.isEdit}
            placeholder={props.placeholder}
            handlePastedText={handlePastedText}
            editorClassName={props.className}
            toolbar={editorToolbarConfig}
            toolbarCustomButtons={[<FontExtension />]}
          />
        )}
      </TextEditorWrapper>
      <TextEditorErrorMessage>{error || ''}</TextEditorErrorMessage>
    </TextEditorContainer>
  );
};

export default compose(
  withState('editorState', 'setEditorState', EditorState.createEmpty()),
  withState('htmlContent', 'setHtmlContent', ''),
  withPropsOnChange(
    ['input'],
    ({ input: { value }, meta, setEditorState, setHtmlContent, ...props }) => {
      if (!props.valueDirty && meta.dirty) {
        return;
      }

      const contentBlock = htmlToDraft(value);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);

      const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      setHtmlContent(html);

      props.setValueDirty(false);
    }
  ),
  withHandlers({
    onEditorStateChange:
      ({ input, setEditorState, setHtmlContent, ...props }) =>
      (editorState) => {
        setEditorState(editorState);

        const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));

        input.onChange(html);
        setHtmlContent(html);
        props.setValueDirty(false);
      },
  })
)(TextEditor);
