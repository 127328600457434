import { ADMIN_ROLE_LIST, REGIONAL_MANAGER_ROLE } from '../consts';

export const createOptions = (options, language) => {
  if (!options || !options.length) return null;

  return options.map((item, i) => (
    <option value={item.id} key={i}>
      {item.value[language] || item.value.en}
    </option>
  ));
};

export const getCompanyOptionLabel = (companyList) => {
  return (companyId) => {
    if (!companyId) return '';
    for (let i = 0; i < companyList.length; i++) {
      if (companyList[i].id === companyId) return companyList[i].name;
    }
  };
};

export const getAttractionOptionLabel = (attractionList) => {
  return (attraction) => {
    const attractionMatch = attractionList.find(
      (item) => item.id === attraction.idAttraction
    );
    return attractionMatch?.name || '';
  };
};

export const getPointOptionLabel = (pointList) => {
  return (currentPoint) => {
    const matchedPoint = pointList.find(
      (point) => point.id === currentPoint.salesPointId
    );

    return matchedPoint?.name || '';
  };
};

export const getCategoryOptionLabel = (categoryList, selectedLanguage) => {
  return (category) => {
    for (let i = 0; i < categoryList.length; i++) {
      if (categoryList[i].id === category.idCategory)
        return (
          categoryList[i].name[selectedLanguage] || categoryList[i].name.en
        );
    }
  };
};

export const getRegionOptionLabel = (regionList, selectedLanguage) => {
  return (region) => {
    for (let i = 0; i < regionList.length; i++) {
      if (regionList[i].id === region.idRegion)
        return regionList[i].name[selectedLanguage] || regionList[i].name.en;
    }
  };
};

export const getAreaOptionLabel = (areaList, selectedLanguage) => {
  return (area) => {
    for (let i = 0; i < areaList.length; i++) {
      if (areaList[i].id === area.idArea)
        return areaList[i].name[selectedLanguage] || areaList[i].name.en || areaList[i].name.cs ;
    }
  };
};

export const getSelectedCompanyAttractions = (
  selectedCompany,
  companyAttractionList,
  role,
  areaFieldValue,
  regionFieldValue
) => {
  if (!selectedCompany) return [];
  
  const companyWithAttractions = companyAttractionList.find(
    (company) => company.id === selectedCompany.id
  );

  if(REGIONAL_MANAGER_ROLE === role) {
    let regionResult = [];
    let result = [];

    companyWithAttractions?.attractions.forEach((company) => {
      regionFieldValue.forEach(region => {
        if(company.regionId === region.idRegion) {
          regionResult.push(company)
        }
      })
    })
    
    if(areaFieldValue.length) {
      regionResult.forEach(region => {
        areaFieldValue.forEach(area => {
          if(area.idArea === region.areaId) result.push(region);
        })
      })
    } else {
      result = regionResult;
    }
    
    return result || [];
  }
  
  return companyWithAttractions?.attractions || [];
};

export const getSelectedCompanyPoints = (selectedCompany, companyPointList) => {
  if (!selectedCompany) return [];

  const companyWithPoints = companyPointList.find(
    (company) => company.id === selectedCompany.id
  );

  return companyWithPoints?.salesPoints || [];
};

export const hideControlPanelForSystemManager = (
  userDataFormRole,
  currentAccRole
) => {
  const SYSTEM_MANAGER = 'systemmanager';
  const hidePanel = true;
  const showPanel = false;

  if (!userDataFormRole) return showPanel;

  if (
    ADMIN_ROLE_LIST.includes(userDataFormRole) &&
    currentAccRole === SYSTEM_MANAGER
  )
    return hidePanel;

  return showPanel;
};
