import { stopSubmit } from 'redux-form';

import {
  addDestinationNotificationApi,
  getDestinationNotificationsListApi,
  editDestinationNotificationApi,
  deleteDestinationNotificationApi,
  getFilteredDestinationNotificationsApi,
} from '../../api/generalNotificationsApi.js';

export const SET_GENERAL_NOTIFICATIONS_LIST = 'SET_GENERAL_NOTIFICATIONS_LIST';
export const IS_LOADED_NOTIFICATIONS = 'IS_LOADED_NOTIFICATIONS';

export const setGeneralNotificationsList = (data) => {
  return { type: SET_GENERAL_NOTIFICATIONS_LIST, payload: { data } };
};

export const setIsLoadedNotifications = (isLoadedNotification) => {
  return { type: IS_LOADED_NOTIFICATIONS, isLoadedNotification };
};

export const addDestinationNotificationThunk = (data) => async () => {
  const response = await addDestinationNotificationApi(data);
  if (response.status < 200 || response.status >= 300) {
    return Promise.reject(response.data.errors);
  }
};

export const getDestinationNotificationsListThunk =
  (destinationId) => async (dispatch) => {
    dispatch(setIsLoadedNotifications(false));
    const response = await getDestinationNotificationsListApi(destinationId);
    if (response.status === 200) {
      dispatch(setGeneralNotificationsList(response.data));
      dispatch(setIsLoadedNotifications(true));
    }
  };

export const getFilteredDestinationNotificationsThunk =
  (filter) => async (dispatch) => {
    const response = await getFilteredDestinationNotificationsApi(filter);
    if (response.status === 200) {
      dispatch(setGeneralNotificationsList(response.data));
    }
  };

export const editDestinationNotificationThunk =
  (notificationData) => async (dispatch) => {
    const response = await editDestinationNotificationApi(notificationData);
    if (response.status < 200 || response.status >= 300) {
      dispatch(
        stopSubmit('NotificationForm', { _error: response.data.errors })
      );
      return Promise.reject(response.data.errors);
    }
  };

export const deleteDestinationNotificationThunk =
  (destinationId, notificationId) => async () => {
    const response = await deleteDestinationNotificationApi(
      destinationId,
      notificationId
    );

    if (response.status < 200 || response.status >= 300) {
      return Promise.reject(response.data.errors);
    }
  };
