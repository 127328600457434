import { Controller, useFormContext } from 'react-hook-form';

import { FormControl, MenuItem, Typography } from '@mui/material';

import { REGIONAL_MANAGER, TYPE_AREA, TYPE_REGION } from '../../constants';
import { ACTIVE_STATUS } from '../constants';

import { SelectHelperText, SetupFormSelectFieldInput } from '../styled';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const getAreaMenuItems = (
  optionList,
  selectedLanguage,
  defaultDestinationLanguage,
  currentUser
) =>
  optionList
    .filter((area) => {
      if (currentUser.role === REGIONAL_MANAGER && currentUser.areas.length) {
        return currentUser.areas.some(userArea => userArea.idArea === area.id);
      }
      return true;
    })
    .map(
    (area) =>
      area.locationStatus === ACTIVE_STATUS && (
        <MenuItem value={area.id} key={area.id}>
          <Typography variant="inherit" noWrap>
            {area.title[selectedLanguage] ||
              area.title.en ||
              area.title[defaultDestinationLanguage]}
          </Typography>
        </MenuItem>
      )
  );

const createAreaOption = (optionsData, selectedLanguage, regionId = '', currentUser) => {
  if (!optionsData) return null;
  
  if (optionsData.enabledLocationTypes.includes(TYPE_REGION)) {
    const selectedRegion = optionsData.locations.find(
      (reg) => reg.id === regionId
    );

    if (!selectedRegion) return null;

    return getAreaMenuItems(
      selectedRegion.children,
      selectedLanguage,
      optionsData.languages[0],
      currentUser
    );
  }

  if (optionsData.enabledLocationTypes.includes(TYPE_AREA)) {
    return getAreaMenuItems(
      optionsData.locations,
      selectedLanguage,
      optionsData.languages[0]
    );
  }

  return null;
};

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
      width: 250,
    },
  },
};
const SetupFormAreaSelectField = ({
  disabled,
  optionsData,
  selectedLanguage,
  name,
  emptyOption,
  regionId,
}) => {
  const { control, setValue } = useFormContext();
  const { t } = useTranslation();
  const currentUser = useSelector(state => state.userReducer.currentUser);

  const required = useCallback(value => (value ? undefined : t('required')), [selectedLanguage]);

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: required
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl error={!!error} fullWidth>
          <SetupFormSelectFieldInput
            value={value}
            disabled={disabled}
            MenuProps={MenuProps}
            onChange={(event) => {
              onChange(event.target.value);
              setValue('locationId', '');
            }}
            size="small"
          >
            {emptyOption && (
              <MenuItem value={emptyOption.value} id={emptyOption.id}>
                <Typography variant="inherit" noWrap>
                  {emptyOption.label}
                </Typography>
              </MenuItem>
            )}
            {createAreaOption(optionsData, selectedLanguage, regionId, currentUser)}
          </SetupFormSelectFieldInput>
          <SelectHelperText>{error?.message}</SelectHelperText>
        </FormControl>
      )}
    />
  );
};

export default SetupFormAreaSelectField;
