import { useTranslation } from 'react-i18next';

import {
  MainImageFormDatePicker,
  MainImageFormTextField,
  MainImageFormImageDrop,
} from './formFields';

import {
  MainImageFormWrapper,
  MainImageFormFieldWrapper,
  MainImageFormFieldTitle,
  ImageDropWrapper,
  MainImageFormUploadInstructionWrapper,
  MainImageFormImageUploadBlock,
} from '../styled';

import { DEFAULT_TEXT_FIELD_MAX_LENGTH } from '../../../../consts/validation';
import { ALLOWED_FILE_TYPES } from '../constants';

const MainImageForm = (props) => {
  const { isInEditMode } = props;

  const { t } = useTranslation();

  const formLabels = t('mainImage.tableLabels', {
    returnObjects: true,
  });

  return (
    <MainImageFormWrapper>
      <form>
        <MainImageFormFieldWrapper>
          <MainImageFormFieldTitle>{formLabels[0]}</MainImageFormFieldTitle>
          <MainImageFormDatePicker disabled={!isInEditMode} name="startDate" />
        </MainImageFormFieldWrapper>
        <MainImageFormFieldWrapper>
          <MainImageFormFieldTitle>{formLabels[1]}</MainImageFormFieldTitle>
          <MainImageFormTextField
            disabled={!isInEditMode}
            name="subject"
            maxLength={DEFAULT_TEXT_FIELD_MAX_LENGTH}
            required
          />
        </MainImageFormFieldWrapper>
        <MainImageFormFieldWrapper>
          <MainImageFormFieldTitle>{formLabels[2]}</MainImageFormFieldTitle>
          <MainImageFormImageUploadBlock>
            <MainImageFormUploadInstructionWrapper>
              {t('settings_page.destination.image_text')}
            </MainImageFormUploadInstructionWrapper>
            <ImageDropWrapper>
              <MainImageFormImageDrop
                name="image"
                disabled={!isInEditMode}
                columns={1}
                filesLimit={1}
                allowedFileTypes={ALLOWED_FILE_TYPES}
              />
            </ImageDropWrapper>
          </MainImageFormImageUploadBlock>
        </MainImageFormFieldWrapper>
      </form>
    </MainImageFormWrapper>
  );
};

export default MainImageForm;
