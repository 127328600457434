import {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import { Field, FormSection } from 'redux-form';
import { useTranslation } from 'react-i18next';

import { Box, Tabs, Stack } from '@mui/material';

import TextEditor from '../RichTextEditor/TextEditor';

import {
  LanguageTabsFieldWrapper,
  LanguageTabsFieldTitle,
  LanguageTabsTextField,
  LanguageTabsContainer,
  LanguageTab,
  LanguageTabPanel,
} from './styled';

import {
  maxLength250,
  hasNotOnlySpaces,
  requiredTextEditor,
  maxLength3000,
} from './validation';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <LanguageTabPanel
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            fontSize: '16px',
            p: 3,
          }}
        >
          {children}
        </Box>
      )}
    </LanguageTabPanel>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const NotificationLanguageTabs = (props) => {
  const { languages, isEdit, selectedLanguage } = props;
  const [tabsValue, setTabsValue] = useState(0);
  const { t } = useTranslation();
  const [valueDirty, setValueDirty] = useState(false);

  const handleChangeTabs = (_, newValue) => {
    setTabsValue(newValue);
    setValueDirty(true);
  };

  const required = useCallback(value => (value ? undefined : t('required')), [selectedLanguage]);

  const LanguageTabs = languages?.map((tab, index) => (
    <LanguageTab label={tab.value} key={tab.id} {...a11yProps(index)} />
  ));

  return (
    <>
      <LanguageTabsContainer>
        <Tabs
          value={tabsValue}
          onChange={handleChangeTabs}
          indicatorColor={undefined}
          sx={{
            '& .MuiTabs-indicator': {
              display: 'none',
            },
          }}
        >
          {LanguageTabs}
        </Tabs>
      </LanguageTabsContainer>
      {languages?.map((tabPanel, index) => (
        <TabPanel value={tabsValue} index={index} key={index}>
          <Stack spacing={2}>
            <LanguageTabsFieldWrapper>
              <LanguageTabsFieldTitle>
                {t('notifications.title')}:
              </LanguageTabsFieldTitle>
              <FormSection name="title">
                <Field
                  name={tabPanel.value}
                  component={LanguageTabsTextField}
                  validate={[required, maxLength250, hasNotOnlySpaces]}
                  disabled={!isEdit}
                />
              </FormSection>
            </LanguageTabsFieldWrapper>
            <LanguageTabsFieldWrapper>
              <LanguageTabsFieldTitle>
                {t('notifications.text')}:
              </LanguageTabsFieldTitle>
              <FormSection name="text">
                <Field
                  name={tabPanel.value}
                  component={TextEditor}
                  isEdit={isEdit}
                  valueDirty={valueDirty}
                  setValueDirty={setValueDirty}
                  validate={[
                    requiredTextEditor,
                    maxLength3000,
                    hasNotOnlySpaces,
                  ]}
                />
              </FormSection>
            </LanguageTabsFieldWrapper>
          </Stack>
        </TabPanel>
      ))}
    </>
  );
};

export default NotificationLanguageTabs;
