import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Checkbox, FormControlLabel } from '@mui/material';
import { IS_GENERAL_NOTIFICATION } from '../constants';

const NotificationCheckboxField = ({ name, disabled, label }) => {
  const { t } = useTranslation();
  const { control, resetField } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, name } }) => (
        <FormControlLabel
          sx={{
            marginLeft: 0,
          }}
          label={label || t('notifications.general_notification')}
          labelPlacement="end"
          control={
            <Checkbox
              disabled={disabled}
              disableRipple
              onChange={(event) => {
                onChange(event.target.checked);
                name === IS_GENERAL_NOTIFICATION &&
                  resetField('attractions', []);
              }}
              checked={value}
              name={name}
              sx={{
                padding: 0,
                paddingRight: '9px',
              }}
            />
          }
        />
      )}
    />
  );
};

export default NotificationCheckboxField;
