import { format } from 'date-fns';
import clsx from 'clsx';

import { TableCell, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { MainImageFormContainer } from '../mainImageForm';

import { StyledMainImageTableRow, TableImageBox } from '../styled';

const useStyles = makeStyles({
  activeRow: {
    backgroundColor: '#FFA94C',
  },
});

const MainImageRow = (props) => {
  const { resetActiveRow, imageRecord, onExpand, isExpanded } = props;
  const { startDate, subject, image } = imageRecord;

  const classes = useStyles();

  const handleExpandTableRow = () => {
    onExpand();
  };

  return (
    <>
      <StyledMainImageTableRow
        onClick={handleExpandTableRow}
        className={clsx({ [classes.activeRow]: isExpanded })}
      >
        <TableCell>{format(new Date(startDate), 'dd/MM/yyyy')}</TableCell>
        <TableCell>{subject}</TableCell>
        <TableCell>
          <TableImageBox component="img" src={image} loading="lazy" alt="" />
        </TableCell>
      </StyledMainImageTableRow>
      {isExpanded && (
        <TableRow>
          <TableCell colSpan={3}>
            <MainImageFormContainer
              imageRecord={imageRecord}
              resetActiveRow={resetActiveRow}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default MainImageRow;
