import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Tab, Tabs, StyledEngineProvider } from '@mui/material';

import cl from './NotificationLanguageTabFields.module.scss';

import {
  NotificationLanguageTabField,
  NotificationFormFieldTitle,
  NotificationFormLanguageTabField,
} from '../styled';

import { validateTextFieldValue } from '../../../../helpers/validateTextFieldValue';
import { TEXT_EDITOR_FIELD_MAX_LENGTH } from '../constants';
import { DEFAULT_TEXT_FIELD_MAX_LENGTH } from '../../../../consts/validation';
import NotificationFormTextEditorField from './NotificationFormTextEditorField';

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const NotificationLanguageTabFields = ({
  languages,
  handleChangeLanguageTab,
  currentTabId,
  disabled,
}) => {
  return (
    <Box className={cl.wrapper}>
      <StyledEngineProvider injectFirst>
        <Box className={cl.tabsContainer}>
          <Tabs
            value={currentTabId}
            onChange={handleChangeLanguageTab}
            indicatorColor={undefined}
            sx={{
              '& .MuiTabs-indicator': {
                display: 'none',
              },
            }}
          >
            {languages.map((tab, index) => (
              <Tab
                value={tab}
                label={tab}
                key={tab.id}
                {...a11yProps(index)}
                className={cl.tab}
              />
            ))}
          </Tabs>
        </Box>
        {createTabPanels({
          languages,
          currentTabId,
          disabled,
        })}
      </StyledEngineProvider>
    </Box>
  );
};

const createTabPanels = ({ languages, currentTabId, disabled }) => {
  return languages.map((languageTab, index) => (
    <TabPanel
      value={currentTabId}
      index={languageTab}
      key={index}
      className={cl.tabPanel}
      fieldArrayName={languageTab}
      disabled={disabled}
    />
  ));
};

const TabPanel = (props) => {
  const { t } = useTranslation();
  const { fieldArrayName, value, index, disabled, ...other } = props;

  const { control } = useFormContext();

  const { fields } = useFieldArray({
    name: fieldArrayName,
    control,
  });

  const isActiveTab = value === index;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {isActiveTab && (
        <Box>
          {fields.map((field, index) => {
            return (
              <Box id={field.id} key={field.id}>
                <NotificationLanguageTabField>
                  <NotificationFormFieldTitle>
                    {t('notifications.title')}:
                  </NotificationFormFieldTitle>
                  <Controller
                    name={`${fieldArrayName}.${index}.title`}
                    control={control}
                    rules={{
                      required: {
                        value: isActiveTab,
                        message: t('attractions.deals.error_required'),
                      },
                      maxLength: {
                        value: DEFAULT_TEXT_FIELD_MAX_LENGTH,
                        message: t('attractions.deals.error_max_length', {
                          chars: DEFAULT_TEXT_FIELD_MAX_LENGTH,
                        }),
                      },
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <NotificationFormLanguageTabField
                        value={value}
                        disabled={disabled}
                        onChange={(event) =>
                          onChange(validateTextFieldValue(event.target.value))
                        }
                        fullWidth
                        size="small"
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </NotificationLanguageTabField>

                <NotificationLanguageTabField>
                  <NotificationFormFieldTitle>
                    {t('notifications.text')}:
                  </NotificationFormFieldTitle>
                  <NotificationFormTextEditorField
                    name={`${fieldArrayName}.${index}.text`}
                    disabled={disabled}
                    maxLength={TEXT_EDITOR_FIELD_MAX_LENGTH}
                    required
                  />
                </NotificationLanguageTabField>
              </Box>
            );
          })}
        </Box>
      )}
    </div>
  );
};

export default NotificationLanguageTabFields;
