import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import MainImageForm from './MainImageForm';
import MainImageControlPanel from '../mainImageControlPanel/MainImageControlPanel';
import useAPIError from '../../../../apIErrorProvider/useAPIError';
import ConfirmationDialog from '../../../../components/confirmationDialog/ConfirmationDialog';

import { uploadImageFile } from '../../../../api/publishing/mainImageApi';
import {
  updateImageRecordThunk,
  deleteImageRecordThunk,
  getImageRecordListThunk,
} from '../../../../redux/publishingReducers/mainImageReducer/mainImageActions';

const MainImageFormContainer = (props) => {
  const {
    imageRecord,
    getImageRecordListThunk,
    deleteImageRecordThunk,
    updateImageRecordThunk,
    resetActiveRow,
    currentDestinationId,
  } = props;

  const { addError } = useAPIError();
  const { t } = useTranslation();

  const [isInEditMode, setIsInEditMode] = useState(false);
  const [showSaveAnimation, setShowSaveAnimation] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const initialFormState = {
    ...imageRecord,
    image: [imageRecord.image],
  };

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      ...initialFormState,
    },
  });

  const { reset, handleSubmit } = formMethods;

  const resetFormValues = () => {
    reset(initialFormState, { keepDefaultValues: true });
  };

  const handleFormSubmit = (formData) => {
    setShowSaveAnimation(true);
    setIsInEditMode(false);

    if (typeof formData.image[0] === 'string') {
      const updatedImageRecord = {
        ...formData,
        id: imageRecord.id,
        image: formData.image[0],
      };

      updateImageRecordThunk(updatedImageRecord.id, updatedImageRecord)
        .then(() => {
          setShowSaveAnimation(false);
          getImageRecordListThunk(currentDestinationId);
          resetActiveRow();
          addError(`${t('mainImage.notifications.update_success')}`, 'Success');
        })
        .catch((e) => {
          setShowSaveAnimation(false);
          resetFormValues();
          addError(`${t('mainImage.notifications.update_error')}`, 'Error', e);
        });
    } else {
      const imageFormData = new FormData();
      imageFormData.append('files', formData.image[0]?.file);

      uploadImageFile(imageFormData, 'App')
        .then((response) => {
          const updatedImageRecord = {
            ...formData,
            id: imageRecord.id,
            image: response.data[0],
          };

          return updateImageRecordThunk(
            updatedImageRecord.id,
            updatedImageRecord
          );
        })
        .then(() => {
          setShowSaveAnimation(false);
          resetActiveRow();
          getImageRecordListThunk(currentDestinationId);
          addError(`${t('mainImage.notifications.update_success')}`, 'Success');
        })
        .catch((e) => {
          setShowSaveAnimation(false);
          resetFormValues();
          addError(`${t('mainImage.notifications.update_error')}`, 'Error', e);
        });
    }
  };

  const hanldeFormDelete = (recordId) => {
    deleteImageRecordThunk(recordId)
      .then(() => {
        addError(`${t('mainImage.notifications.delete_success')}`, 'Success');
        resetActiveRow();
        getImageRecordListThunk(currentDestinationId);
      })
      .catch((e) => {
        addError(`${t('mainImage.notifications.delete_error')}`, 'Error', e);
      });
  };

  const handleFormEdit = () => {
    setIsInEditMode(true);
  };

  const handleFormCancel = () => {
    resetFormValues();
    setIsInEditMode(false);
  };

  const handleDeleteButtonClick = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handlePopupConfirm = () => {
    setIsPopupOpen(false);
    setIsInEditMode(false);
    setShowSaveAnimation(false);
    hanldeFormDelete(imageRecord.id);
  };

  return (
    <FormProvider {...formMethods}>
      <MainImageControlPanel
        isInEditMode={isInEditMode}
        onSubmit={handleSubmit(handleFormSubmit)}
        onCancel={handleFormCancel}
        onEdit={handleFormEdit}
        onDelete={handleDeleteButtonClick}
        showSaveAnimation={showSaveAnimation}
      />
      <MainImageForm isInEditMode={isInEditMode} />

      <ConfirmationDialog
        showAlert={isPopupOpen}
        handleClose={handleClosePopup}
        handleCloseSuccess={handlePopupConfirm}
        text={t('mainImage.delete_image_title')}
      />
    </FormProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    currentDestinationId: state.appReducer.selectedDestination.id,
  };
};

export default connect(mapStateToProps, {
  getImageRecordListThunk,
  updateImageRecordThunk,
  deleteImageRecordThunk,
})(MainImageFormContainer);
