import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import MainImageForm from './MainImageForm';
import MainImageControlPanel from '../mainImageControlPanel/MainImageControlPanel';
import useAPIError from '../../../../apIErrorProvider/useAPIError';

import { uploadImageFile } from '../../../../api/publishing/mainImageApi';
import { createImageRecordThunk } from '../../../../redux/publishingReducers/mainImageReducer/mainImageActions';

const MainImageAddFormContainer = (props) => {
  const { createImageRecordThunk, currentDestinationId } = props;

  const { addError } = useAPIError();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showSaveAnimation, setShowSaveAnimation] = useState(false);

  const initialFormState = {
    startDate: new Date(),
    subject: '',
    image: [],
  };

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      ...initialFormState,
    },
  });

  const { handleSubmit } = formMethods;

  const handleFormSubmit = (formData) => {
    setShowSaveAnimation(true);

    const imageFormData = new FormData();
    imageFormData.append('files', formData.image[0]?.file);

    uploadImageFile(imageFormData, 'App')
      .then((response) => {
        const newImageRecord = {
          ...formData,
          destinationId: currentDestinationId,
          image: response.data[0],
        };

        return createImageRecordThunk(newImageRecord);
      })
      .then(() => {
        setShowSaveAnimation(false);
        addError(`${t('mainImage.notifications.add_success')}`, 'Success');
        navigate(-1);
      })
      .catch((e) => {
        addError(`${t('mainImage.notifications.add_error')}`, 'Error', e);
        setShowSaveAnimation(false);
      });
  };

  const handleFormCancel = () => {
    navigate(-1);
  };

  return (
    <FormProvider {...formMethods}>
      <MainImageControlPanel
        isInAddMode
        isInEditMode
        onSubmit={handleSubmit(handleFormSubmit)}
        onCancel={handleFormCancel}
        showSaveAnimation={showSaveAnimation}
      />
      <MainImageForm isInEditMode />
    </FormProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    currentDestinationId: state.appReducer.selectedDestination.id,
  };
};

export default connect(mapStateToProps, { createImageRecordThunk })(
  MainImageAddFormContainer
);
