import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { reset } from 'redux-form';

import { Box, Typography } from '@mui/material';

import DealsFilterForm from './forms/DealsFilterForm';
import useAPIError from '../../apIErrorProvider/useAPIError';
import DealsTable from './components/DealsTable';

import { DealContext } from './context/DealContext';
import { addDealApproveThunk, setTableSettings } from '../../redux/attractionsReducers/deals/dealsActions';
import {
  getDealsListThunk,
  getAllDealsListThunk,
  loadDealsListThunk,
  setIsItemUpdating,
  setDealsList,
} from '../../redux/attractionsReducers/deals/dealsActions';
import { editDealObject } from './helpers';
import { DEFAULT_PAGE_NUMBER } from './consts';
import {
  DealAddButton,
  DealsPageContainer,
  DealsTableFilterFormWrapper,
} from './styled';
import { setTitle } from '../../redux/appReducer';

const Deals = (props) => {
  const {
    appReducer,
    dealsList,
    dealFilterStatuses,
    dealStatuses,
    selectedLanguage,
    benefitOptions,
    languages,
    currentAttractionId,
    tableSettings,
    isDataLoading,
    attractionsReducer,
    getSmallAttractionsDataThunk,
    getPredefinedSettingsThunk
  } = props;

  const { t } = useTranslation();
  const { addError } = useAPIError();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showSaveAnimation, setShowSaveAnimation] = useState(false);
  const [dealStatus, setDealStatus] = useState('');
  const [attraction, setAttraction] = useState('');
  const [dealsWithAttractionNames, setDealsWithAttractionNames] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const { pageNumberLazy } = tableSettings
  const [isAllPagination, setIsAllPagination] = useState(true);

  const selectedDestinationId = appReducer.selectedDestination.id;

  const currentUser = useSelector(state => state.userReducer.currentUser);

  const handlePaginationSettings = (pageSize, pageNumber) => {
    dispatch(
      setTableSettings({
        ...tableSettings,
        pageNumber,
        pageNumberLazy: 1,
        pageSize,
      })
    );
  };

  useEffect(() => {
    dispatch((setTitle('top_bar.main_title_deals')));
    getPredefinedSettingsThunk();
  }, []);

  const handleResetFilters = () => {
    setDealStatus('');
    setAttraction('');

    const filter = {
      status: '',
      attraction: ''
    };

    dispatch(reset('DealsFilterForm'));
    onSubmitDealSearchForm(filter);
  };

  useEffect(() => {
    document.addEventListener('scroll', scrollHandler)

    return () => {
      document.removeEventListener('scroll', scrollHandler)
    }
  })

  useEffect(() => {
    if(isFetching && selectedDestinationId) {
     dispatch(
        loadDealsListThunk({
          destinationId: selectedDestinationId,
          pageNumber: tableSettings.pageNumberLazy,
          pageSize: tableSettings.pageSize,
          status: dealStatus,
        })
      ).then(() => {
        dispatch(
          setTableSettings({
            ...tableSettings,
            pageNumberLazy: tableSettings.pageNumberLazy + 1,
          })
        );
      }).then(() => {
        setIsFetching(false);
      });
    }
  }, [isFetching, isAllPagination])

  const scrollHandler = (e) => {
    const [ fullHeigth, heigthFromTop, windowHeight ] =  [
      e.target.documentElement.scrollHeight, 
      e.target.documentElement.scrollTop, 
      window.innerHeight
    ]
    if(
      fullHeigth - (heigthFromTop + windowHeight) < 800 && 
      dealsList.length < tableSettings.totalItems &&
      isAllPagination
      ) {
      setIsFetching(true);
    }
  }

  const handleStatusChange = (value) => {
    handlePaginationSettings(50, DEFAULT_PAGE_NUMBER);
    setDealStatus(value);

    setIsFetching(false);
  };

  const handlePageSizeChange = (e) => {
    if(e.target.value == 50) setIsAllPagination(true)
    else setIsAllPagination(false);

    setIsFetching(false);

    handlePaginationSettings(parseInt(e.target.value, 10), DEFAULT_PAGE_NUMBER);
  };

  const handlePageNumberChange = (_, newPageNumber) => {
    handlePaginationSettings(tableSettings.pageSize, newPageNumber);
  };

  const handleAddButtonClick = () => {
    navigate(`/deals/new-deal`);
    handlePaginationSettings(50, DEFAULT_PAGE_NUMBER);
  };

  const handleSubmitForm = (values) => {
    const filter = {
      destinationId: selectedDestinationId,
      attractionId: attraction?.id,
      pageNumber: tableSettings.pageNumber,
      pageSize: tableSettings.pageSize,
      status: dealStatus,
    };

    setShowSaveAnimation(true);
    const updatedDeal = editDealObject(currentAttractionId, values);    
    props
      .editDealThunk(updatedDeal.id, updatedDeal)
      .then(() => {
        dispatch(
          getAllDealsListThunk(filter)
        );
        setShowSaveAnimation(false);
        addError(`${t('attractions.deals.success_edit_deal')}`, 'Success');
      })
      .catch((error) => {
        addError(`${t('attractions.deals.error_edit_deal')}`, 'Error', error);
        setShowSaveAnimation(false);
      });
  };

  const handleDeleteForm = (itemId) => {
    const filter = {
      destinationId: selectedDestinationId,
      attractionId: attraction?.id,
      pageNumber: tableSettings.pageNumber,
      pageSize: tableSettings.pageSize,
      status: dealStatus
    };

    setShowSaveAnimation(true);
    props
      .deleteDealThunk(itemId)
      .then(() => {
        dispatch(
          getAllDealsListThunk(filter)
        );
        setShowSaveAnimation(false);
        addError(`${t('attractions.deals.success_delete_deal')}`, 'Success');
      })
      .catch((error) => {
        addError(`${t('attractions.deals.error_delete_deal')}`, 'Error', error);
        setShowSaveAnimation(false);
      });
  };

  const handleDealApprove = (itemId) => {
    const filter = {
      destinationId: selectedDestinationId,
      attractionId: attraction?.id,
      pageNumber: tableSettings.pageNumber,
      pageSize: tableSettings.pageSize,
      status: dealStatus
    };

    setShowSaveAnimation(true);
    dispatch(addDealApproveThunk(itemId, {Id: itemId, UserId: currentUser.id}))
      .then(() => {
        dispatch(
          getAllDealsListThunk(filter)
        );
        setShowSaveAnimation(false);
        addError(`${t('attractions.setup.error_edit_text')}`, 'Success');
      })
      .catch((error) => {
        setShowSaveAnimation(false);
        addError(
          `${t('attractions.setup.error_not_edit_text')}`,
          'Error',
          error
        );
      });
  }

  const onSubmitDealSearchForm = (formData) => {
    const filter = {
      destinationId: selectedDestinationId,
      attractionId: formData.attraction ? formData.attraction.id : null,
      pageNumber: tableSettings.pageNumber,
      pageSize: tableSettings.pageSize,
      status: formData.status,
    };

    dispatch(getAllDealsListThunk(filter));
  };

  const availableAttractions = attractionsReducer.attractionsSmallData.sort(
    function (a, b) {
      var textA = a.name.toUpperCase().trim();
      var textB = b.name.toUpperCase().trim();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    }
  );

  useEffect(() => {
    dispatch(setIsItemUpdating(false));
    getSmallAttractionsDataThunk(selectedDestinationId);

    if(selectedDestinationId) {
      dispatch(getAllDealsListThunk({
        destinationId: selectedDestinationId,
        pageNumber: tableSettings.pageNumber,
        pageSize: tableSettings.pageSize,
        status: dealStatus,
      }));
    }

    return () => dispatch(setDealsList([]));
  }, [
    selectedDestinationId,
    currentAttractionId,
    tableSettings.pageNumber,
    tableSettings.pageSize,
  ]);

  useEffect(() => {
    const attractionsMap = {};
    availableAttractions.forEach(attraction => {
      attractionsMap[attraction.id] = attraction.name;
    });
  
    const dealsWithAttractionNamesUpdated = dealsList.map(deal => ({
      ...deal,
      attractionName: attractionsMap[deal.attractionId]
    }));
  
    const sortedDeals = [...dealsWithAttractionNamesUpdated].sort((dealA, dealB) => {
      const attractionNameA = dealA.attractionName?.toUpperCase().trim();
      const attractionNameB = dealB.attractionName?.toUpperCase().trim();
      return attractionNameA < attractionNameB ? -1 : attractionNameA > attractionNameB ? 1 : 0;
    });
  
    setDealsWithAttractionNames(sortedDeals);
  }, [dealsList, availableAttractions]);

  return (
    <DealsPageContainer>
      <Box mb="10px">
        <Typography fontWeight={500} fontSize="20px" textTransform="uppercase">
          {t('attractions.deals.deals_list_title')}
        </Typography>
      </Box>

      <Box>
        <DealAddButton variant="contained" onClick={handleAddButtonClick}>
          {t('attractions.deals.buttons.add_new_deal')}
        </DealAddButton>
      </Box>
      <DealsTableFilterFormWrapper>
        <DealsFilterForm
          availableStatuses={dealFilterStatuses}
          availableAttractions={availableAttractions}
          onSubmit={onSubmitDealSearchForm}
          attraction={attraction}
          setAttraction={setAttraction}
          dealStatus={dealStatus}
          setDealStatus={handleStatusChange}
          value={dealStatus}
          selectedLanguage={selectedLanguage}
          handleResetFilters={handleResetFilters}
        />
      </DealsTableFilterFormWrapper>
      <DealContext.Provider
        value={{
          formActions: {
            save: handleSubmitForm,
            delete: handleDeleteForm,
            approve: handleDealApprove
          },
          selectedLanguage,
          dealStatuses,
          benefitOptions,
          showSaveAnimation,
          languages,
        }}
      >
        <DealsTable
          items={dealsWithAttractionNames}
          tableSettings={tableSettings}
          onPageSizeChange={handlePageSizeChange}
          onPageNumberChange={handlePageNumberChange}
          isDataLoading={isDataLoading}
          isAllPagination = {isAllPagination}
          isFetching = {isFetching}
        />
      </DealContext.Provider>
    </DealsPageContainer>
  );
};

export default Deals;
