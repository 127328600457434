import * as axios from 'axios';

import authService from '../auth/AuthorizeService';
import { BASE_URL } from '../config.js';

export const addDestinationNotificationApi = async (data) => {
  const token = await authService.getAccessToken();
  return await axios
    .post(
      `${BASE_URL}/api/destinations/${data.destinationId}/notifications`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .catch((error) => {
      return error.response;
    });
};

export const editDestinationNotificationApi = async (data) => {
  const token = await authService.getAccessToken();
  return await axios
    .put(
      `${BASE_URL}/api/destinations/${data.destinationId}/notifications/${data.notificationId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .catch((error) => {
      return error.response;
    });
};

export const deleteDestinationNotificationApi = async (
  destinationId,
  notificationId
) => {
  const token = await authService.getAccessToken();
  return await axios
    .delete(
      `${BASE_URL}/api/destinations/${destinationId}/notifications/${notificationId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .catch((error) => {
      return error.response;
    });
};

export const getFilteredDestinationNotificationsApi = async (filter) => {
  const token = await authService.getAccessToken();
  const targetUrl = new URL(
    `${BASE_URL}/api/destinations/${filter.destinationId}/notifications`
  );

  filter.attractionId &&
    targetUrl.searchParams.append('attractionId', filter.attractionId);
  filter.status && targetUrl.searchParams.append('status', filter.status);
  filter.startDate &&
    targetUrl.searchParams.append('startDate', filter.startDate);
  filter.endDate && targetUrl.searchParams.append('endDate', filter.endDate);

  return await axios
    .get(targetUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const getDestinationNotificationsListApi = async (destinationId) => {
  const token = await authService.getAccessToken();
  return await axios
    .get(`${BASE_URL}/api/destinations/${destinationId}/notifications`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};
