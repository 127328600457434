import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useNavigate } from 'react-router-dom';

import { Container } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import theme from './theme/theme.js';

import APIErrorProvider from './apIErrorProvider/apIErrorProvider';
import APIErrorAlert from './apIErrorProvider/APIErrorAlert';
import TopBarContainer from './pages/topBar/TopBarContainer';
import { ApplicationPaths } from './auth/ApiAuthorizationConstants';
import AppRoutes from './navigation/AppRoutes.jsx';
import UnsavedChangesAlert from './components/alert/TabChangeAlert.jsx';

import { getProfileData } from './redux/authReducer';
import { getGlobalSettingsThunk, setCurrentLanguage } from './redux/appReducer';
import { getDestinationThunk } from './redux/settingsReducers/settingsReducer';
import { getCurrentUserThunk } from './redux/usersReducers/users/userActions';
import {
  setIsRouteChangeConfirmed,
  setShowUnsavedChangesAlert,
  setTargetLocation,
} from './redux/routeChangeReducer/routeChangeActions.js';
import { CLIENT_ID, IDENTITY_SERVER_URL } from './config.js';
import i18next from 'i18next';

import { HubConnectionBuilder } from '@microsoft/signalr';
import { ACCOUNT_BASE_URL } from './config.js';
import { LOGOUT_MESSAGE } from './consts/consts.js';

const App = (props) => {
  const {
    routeChangeReducer,
    setShowUnsavedChangesAlert,
    setIsRouteChangeConfirmed,
    setTargetLocation,
    currentUser,
    setCurrentLanguage,
  } = props;
  const { showUnsavedChangesAlert } = routeChangeReducer;

  const navigate = useNavigate();
  let person = JSON.parse(
    localStorage.getItem(
      `CoolPass.ReactLoginSampleuser:${IDENTITY_SERVER_URL}:${CLIENT_ID}`
    )
  );

  const [initialized] = useState(
    !!person && person.expires_at > Date.parse(new Date()) / 1000
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleAlertSubmit = () => {
    setShowUnsavedChangesAlert(false);
    setIsRouteChangeConfirmed(true);
  };

  const handleAlertClose = () => {
    setShowUnsavedChangesAlert(false);
    setTargetLocation(null);
  };

  // useEffect(() => {
  //   navigate('/', { replace: true });
  // }, []);

  useEffect(() => {
    if (!initialized) {
      navigate(`${ApplicationPaths.Login}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialized) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      person = JSON.parse(
        localStorage.getItem(
          `CoolPass.ReactLoginSampleuser:${IDENTITY_SERVER_URL}:${CLIENT_ID}`
        )
      );
      const filteredRole = Array.isArray(person.profile.role) 
        ? person.profile.role.filter(role => role !== 'visitor')[0]
        : person.profile.role === 'visitor' ? null : person.profile.role;
      
      props.getProfileData(
        person.profile.name,
        person.profile.picture,
        filteredRole
      );
      Promise.all([
        props.getDestinationThunk(),
        props.getGlobalSettingsThunk(),
        props.getCurrentUserThunk(),
      ]).then(() => {
        setIsLoading(true);
      });
    }
  }, [initialized]);

  useEffect(() => {
    const appLanguage =
      currentUser?.language || localStorage.getItem('i18nextLng');

    setCurrentLanguage(appLanguage);
    i18next.changeLanguage(appLanguage);
  }, [initialized, setCurrentLanguage]);
  
  const [connection, setConnection] = useState(null);

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(`${ACCOUNT_BASE_URL}/usersHub`)
      .build();

    setConnection(newConnection);
  }, []);

  useEffect(() => {
    if (connection && currentUser?.id) {
      connection.start()
        .then(() => {
          connection.invoke("JoinGroup", currentUser.id.toString());
        })
        .catch(error => console.error(error));

      connection.on("ReceiveMessage", message => {
        if (message === LOGOUT_MESSAGE) {
            handleLogout();
        }
      });
    }
  }, [connection, currentUser?.id]);

  const handleLogout = () => {
    navigate(ApplicationPaths.LogOut);
  };

  return (
    <ThemeProvider theme={theme}>
      <APIErrorProvider>
        <Container maxWidth="xl">
          {initialized && isLoading && (
            <TopBarContainer
              name={props.authReducer.name}
              picture={props.authReducer.picture}
              currentUser={currentUser}
            />
          )}
          <AppRoutes initialized={initialized} isLoading={isLoading} />
        </Container>
        <UnsavedChangesAlert
          onCancel={handleAlertClose}
          onSubmit={handleAlertSubmit}
          show={showUnsavedChangesAlert}
        />
        <APIErrorAlert />
      </APIErrorProvider>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    authReducer: state.authReducer,
    appReducer: state.appReducer,
    currentUser: state.userReducer.currentUser,
    routeChangeReducer: state.routeChangeReducer,
  };
};

export default compose(
  connect(mapStateToProps, {
    getProfileData,
    getGlobalSettingsThunk,
    setCurrentLanguage,
    getDestinationThunk,
    getCurrentUserThunk,
    setShowUnsavedChangesAlert,
    setIsRouteChangeConfirmed,
    setTargetLocation,
  })
)(App);
