import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Table,
  TableCell,
  TableRow,
  TableContainer,
  Paper,
  TableHead,
  TableBody,
  TablePagination,
  Box,
  CircularProgress
} from '@mui/material';

import Deal from './Deal';

import cl from './styles/DealsTable.module.scss';

const DealsTable = (props) => {
  const { t } = useTranslation();
  const { items, tableSettings, onPageSizeChange, onPageNumberChange, isAllPagination } = props;

  const [expandedId, setExpandedId] = useState(null);

  const handlePanelExpandChange = (panelId) => (_, isExpanded) => {
    setExpandedId(isExpanded ? panelId : false);
  };

  const TABLE_PAGINATION_OPTIONS = [
    { label: t('all_pagination_option'), value: 50 },
    { label: '20', value: 20 },
    { label: '60', value: 60 },
    { label: '100', value: 100 },
  ];

  const CreateTableHead = () => (
    <TableHead>
      <TableRow className={cl.tableRow}>
        <TableCell className={cl.tableCell}>
          {t('attractions.deals.status')}
        </TableCell>
        <TableCell className={cl.tableCell}>
          {t('attractions.deals.attraction')}
        </TableCell>
        <TableCell className={cl.tableCell}>
          {t('attractions.deals.benefit')}
        </TableCell>
        <TableCell className={cl.tableCell}>
          {t('attractions.deals.title')}
        </TableCell>
        <TableCell className={cl.tableCell}>
          {t('attractions.deals.start_date')}
        </TableCell>
        <TableCell className={cl.tableCell}>
          {t('attractions.deals.end_date')}
        </TableCell>
        <TableCell className={cl.tableCell} align="center">
          {t('attractions.deals.view')}
        </TableCell>
      </TableRow>
    </TableHead>
  );

  const createTableItems = (items) => {
    return items.map((item, index) => (
      <Deal
        item={item}
        key={index}
        expanded={expandedId === item.id}
        onExpandChange={() => handlePanelExpandChange(item.id)}
      />
    ));
  };
  return (
    <Box>
      <TablePagination
        rowsPerPageOptions={TABLE_PAGINATION_OPTIONS}
        onRowsPerPageChange={onPageSizeChange}
        component="div"
        count={tableSettings.totalItems}
        page={tableSettings.pageNumber}
        onPageChange={onPageNumberChange}
        rowsPerPage={tableSettings.pageSize}
        labelRowsPerPage={t('attractions.deals.rows_per_page')}
        labelDisplayedRows={({ from, to, count }) => 
          isAllPagination 
            ? `${tableSettings.totalItems}-${tableSettings.totalItems} of ${tableSettings.totalItems}`
            : `${from}-${to} of ${count}`
          }
        nextIconButtonProps={{
          style: isAllPagination ? { display: 'none' } : {}
        }}
        backIconButtonProps={{
          style: isAllPagination ? { display: 'none' } : {}
        }}
      />

      {props.isDataLoading ? (
        <Box
          component="div"
          sx={{ width: '100%', position: 'relative'}}
        >
          <CircularProgress
            sx={{ position: 'absolute', right: '50%', top: '200px' }}
          />
        </Box>
      ) : (
        <>
          <TableContainer
            component={Paper}
            square
            elevation={0}
            className={cl.tableContainer}
          >
            <Table className={cl.table}>
              <CreateTableHead />
              <TableBody>{items && createTableItems(items)}</TableBody>
            </Table>
          </TableContainer>
          {props.isFetching && 
            <Box display="flex" marginTop="50px" justifyContent="center">
              <CircularProgress />
            </Box>
          }

          <TablePagination
            rowsPerPageOptions={TABLE_PAGINATION_OPTIONS}
            onRowsPerPageChange={onPageSizeChange}
            component="div"
            count={tableSettings.totalItems}
            page={tableSettings.pageNumber}
            onPageChange={onPageNumberChange}
            rowsPerPage={tableSettings.pageSize}
            labelRowsPerPage={t('attractions.deals.rows_per_page')}
            labelDisplayedRows={({ from, to, count }) => 
              isAllPagination 
                ? `${tableSettings.totalItems}-${tableSettings.totalItems} of ${tableSettings.totalItems}`
                : `${from}-${to} of ${count}`
              }
            nextIconButtonProps={{
              style: isAllPagination ? { display: 'none' } : {}
            }}
            backIconButtonProps={{
              style: isAllPagination ? { display: 'none' } : {}
            }}
          />
        </>
      )}
      
    </Box>
  );
};

export default DealsTable;
